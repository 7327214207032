<template>
    <div>
      <TextBox
        :cefrLevel="payload.cefr_level"
        :isTitle="true"
        :label="'Title'"
        placeholder="Title"
        v-model="title"/>
       <br>

      <div class="chat-array">
        <label>Gapfill chat</label>
        <div class="person">
          <div class="profile">
            <img :src="require(`@/assets/images/chat-avatars/${person1}`)" alt="">
          </div>
          <div class="text">
            <component
              ref="person1"
              :is="resolveComponent(gapfill_chat_array[0].text, 'person1')"
              :answer="questions[0].question_options[0].value"
              :placeholder="'Insert Gapfill text here'"
              :cefrLevel="payload.cefr_level"
              v-model="gapfill_chat_array[0].text"
              @updateAnswer="setAnswer">
            </component>
          </div>
        </div>
         <div class="person">
          <div class="text">
            <component
              ref="person2"
              :is="resolveComponent(gapfill_chat_array[1].text, 'person2')"
              :answer="questions[0].question_options[0].value"
              :placeholder="'Insert Gapfill text here'"
              :cefrLevel="payload.cefr_level"
              v-model="gapfill_chat_array[1].text"
              @updateAnswer="setAnswer"
              @focus="focusUpdate($event)">
            </component>
          </div>
          <div class="profile">
            <img :src="require(`@/assets/images/chat-avatars/${person2}`)" alt="">
          </div>
        </div>
      </div>

      <br>
      <OptionsRadio
        v-model="questions[0]"
        :noQuestion="true"
        :cefrLevel="payload.cefr_level"
        :last="true"/>

    </div>
</template>

<script>
import { mapState } from 'vuex'
import TextBox from '../../../components/item/TextBox'
import Textarea from '../../../components/item/Textarea'
import OptionsRadio from '../../../components/item/OptionsRadio'
import GapfillTextarea from '../../../components/item/GapfillTextarea'
import { v4 as uuidv4 } from 'uuid'

export default {
    data: () => ({
        template:'MCQ_GAPFILL_CHAT',
        title:'',
        gapfill_chat_array: [
          { gender: 'UNKNOWN', text: '' },
          { gender: 'UNKNOWN', text: '' }
        ],
        questions: [
          {
            question: '',
            question_id: uuidv4(),
            solution: [''],
            question_options:[
              {
                  value:'',
                  option_id: uuidv4()
              },
              {
                  value:'',
                  option_id: uuidv4()
              },
              {
                  value:'',
                  option_id: uuidv4()
              },
            ],
          }
        ],
        chatAvatars: [
          'avatar_01.svg',
          'avatar_02.svg',
          'avatar_03.svg',
          'avatar_04.svg',
          'avatar_06.svg',
        ],
        person1: 'avatar_01.svg',
        person2: 'avatar_02.svg',
    }),
    computed: {
      ...mapState('item', ['payload', 'isUpdate']),
      person1Text() {
        return this.gapfill_chat_array[0].text
      },
      person2Text() {
        return this.gapfill_chat_array[1].text
      }
    },
    methods:{
        focusUpdate(event){
          console.log(event)
        },
        onSubmit() {
            // When creating an item, set the question's solution[id] to be the option_id of the first question_option
            if (!this.isUpdate) this.questions[0].solution[0] = this.questions[0].question_options[0].option_id

            const data = {
                template_type:this.payload.template ? this.payload.template : this.template,
                title:this.title,
                gapfill_chat_array: this.gapfill_chat_array,
                questions: this.questions
            }
            this.$store.commit('item/setPayload', data)
        },
        resolveComponent(text) {
          if(text?.includes('<%GAP%>') || text?.includes('[') || text?.includes(']'))
            return 'GapfillTextarea'
          else
            return 'Textarea'
        },
        setAnswer(value) {
          const question = this.questions[0];
          question.question_options[0].value = value
          this.question = question
        },
    },
    watch: {
        person1Text: {
          handler() {
            this.$nextTick(() => {
              this.$refs.person1.$el.getElementsByTagName('textarea')[0].focus()
            });
          },
        },
        person2Text: {
          handler() {
            this.$nextTick(() => {
              this.$refs.person2.$el.getElementsByTagName('textarea')[0].focus()
            });
          },
        }
    },

    beforeMount(){
      if (this.isUpdate && Object.keys(this.payload).length) {
        this.title = this.payload.title || ''
        this.gapfill_chat_array = this.payload.gapfill_chat_array
        this.questions = this.payload.questions

        // Make sure that the solution is first in the list of question options
        const { question, question_id, question_options, solution } = this.payload.questions[0];
        const solutionIndex = question_options.findIndex(option => option.option_id === solution[0]);
        const solutionOption = question_options[solutionIndex];
        question_options.splice(solutionIndex, 1);
        question_options.unshift(solutionOption);

        this.questions[0] = {
          question,
          question_id,
          question_options,
          solution,
        }
      }

      const randomIndex =  Math.floor(Math.random()*this.chatAvatars.length)
      this.person1 = this.chatAvatars[randomIndex]
      this.chatAvatars.splice(randomIndex, 1)

      this.person2 = this.chatAvatars[Math.floor(Math.random()*this.chatAvatars.length)]

    },
    components:{
      TextBox,
      Textarea,
      GapfillTextarea,
      OptionsRadio
    }
}

</script>

<style lang="scss" scoped>


.chat-array {
  display: flex;
  flex-direction: column;
  max-width: 500pt;

  .person {
    display: flex;
    gap: 10pt;
    .profile {
      height: 50pt;
      width: 50pt;
      background-color: #e7e7e7;
      border-radius: 6.5pt;

      img {
        width: 100%;
      }
    }
    .text {
      flex: 1;
    }
  }
}

label {
    color: #264D98;
    font-size: 1em;
    margin-bottom: 5pt;
}

</style>